<template>
    <div style="background-color:#fee28d;min-height: 100vh;">
      <div class="topBg display_flex" :style="{ backgroundImage: 'url(img/topBg.png)' }">
        <div class="top_title">
          <img class="top_back padding-sm" :src="'img/backw.png'" alt="" @click="closePage">
        </div>
  
      </div>
      <div class="index_code" style="background-image: url(img/index_code.png);">
        <div class="display_flex align-items_center" style="justify-content: space-around;margin-top: 15px;">
          <div class="invite_code" style="background-image: url(img/invite_code.png);"></div>
          <div style="display:flex;align-items: center;">
            <!-- <div>{{ saveData.introduce_code }}</div> -->
            <div style="font-size: 19px;color: #440705;font-weight: 700;">{{ indexData.invite_code }}</div>
            <button class="tag-read index_btn margin-left" style="background-image: url(img/index_copy.png);"
              :data-clipboard-text="saveData.introduce_code" @click="copy"></button>
          </div>
  
  
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 40px;">
          <div style="flex: 1;text-align: center;">
            <div style="font-size: 28px;font-weight: 700;color: #ff5943;">{{indexData.total_money}}</div>
            <div style="font-size: 16px;">累计奖励</div>
          </div>
          <div style="flex: 1;text-align: center;">
            <div style="font-size: 28px;font-weight: 700;color: #ff5943;">{{indexData.current_money}}</div>
            <div style="font-size: 16px;">剩余奖励</div>
          </div>
        </div>
        <div style="background-image: url(img/invite_firend.png);" class="invite_btn" @click="goSave"></div>
      </div>
      <div class="value_box">
        <div class="value_item" style="background-image: url(img/juxing.png);">
          <div style="font-size:18px;color:#720000;font-weight: 700;margin-top: 25px;">{{indexData.invite_num}}</div>
          <div style="font-size: 12px;color: #420001;margin-top: 6px;">邀请人数(人)</div>
        </div>
        <div class="value_item" style="background-image: url(img/juxing.png);">
          <div style="font-size:18px;color:#720000;font-weight: 700;margin-top: 25px;">{{indexData.total_send_money}}</div>
          <div style="font-size: 12px;color: #420001;margin-top: 6px;">累计赠送礼物(元)</div>
        </div>
        <div class="value_item" style="background-image: url(img/juxing.png);">
          <div style="font-size:18px;color:#720000;font-weight: 700;margin-top: 25px;">{{indexData.total_recharge}}</div>
          <div style="font-size: 12px;color: #420001;margin-top: 6px;">累计充值(元)</div>
        </div>
  
        <!-- <div class="top_item">
          <p>邀请人数<span>(人)</span></p>
          <div class="top_yellow">{{ indexData.invite_num }}</div>
        </div> -->
        <!-- <div class="top_item">
              <p>二级邀请人数<span>(人)</span></p>
              <div class="top_yellow">{{indexData.p_invite_num}}</div>
          </div> -->
        <!-- <div class="top_item">
          <p>累计赠送礼物<span>(元)</span></p>
          <div class="top_yellow">{{ indexData.send_money }}</div>
        </div> -->
        <!-- <div class="top_item">
              <p>二级累计赠送礼物<span>(元)</span></p>
              <div class="top_yellow">{{indexData.p_send_money}}</div>
          </div>
          <div class="top_item">
              <p>下级累计解锁微信<span>(次)</span></p>
              <div class="top_yellow">{{indexData.p_valid_wx}}</div>
          </div>
          <div class="top_item">
              <p>二级累计解锁微信<span>(次)</span></p>
              <div class="top_yellow">{{indexData.pp_valid_wx}}</div>
          </div> -->
      </div>
      <div class="tab_box">
        <div style="background-image: url(img/my_record.png);" class="list_tab">
          <router-link style="width:100%;height: 100%;display: block;" :to="'/myInvite?invite_num=' + indexData.invite_num + '&send_money=' + indexData.total_send_money+ '&effective_num=' + indexData.total_recharge"></router-link>
        </div>
        <!-- <div style="background-image: url(img/my_money.png);" class="money_tab">
          <router-link style="width:100%;height: 100%;display: block;" :to="'/withDraw?current_Money=' + indexData.current_money"></router-link>
        </div> -->
      </div>
      <div class="top_rule" @click="goRule">
        <img src="img/question.png" alt="">
      </div>
  
      <wechat-share @childFn="parentFn" @saveData="getSaveData" />
  
    </div>
  </template>
  
  <script>
  import Clipboard from "clipboard";
  import "@/assets/css/base.css"
  import "@/assets/css/index.css"
  export default {
    name: 'firstRecharge',
    data() {
      return {
      };
    },
    created() {
    },
    methods: {
      closePage() {
        let params = '调用'
        this.$bridge.callhandler('closePage', params, (data) => {
          // 处理返回数据
        })
      }
  
    }
  };
  </script>
  